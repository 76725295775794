import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react'
import { PiPhoneCallBold } from "react-icons/pi";
const popins = Poppins({weight:"400",subsets:['latin'],})
const popinsfw = Poppins({weight:"500",subsets:['latin'],})
const extrapopins = Poppins({weight:"600", subsets:['latin'],});
const quickSand = Quicksand({weight:['400','500'],subsets:['latin'],})
export default function HeroSection() {
  return (
    <>
    <section className='home-bg pdtb70 mpdtb40'>
       <div className='container'>
         <div className='df fac fjc  gap40 mgap0 tfdc'>
            {/* Content */}
            <div className='w50p mwap'>
            <h2 className={`fs-40 mfs-24  ${extrapopins.className}`}>Learn Skills From Our Top  Instructors</h2>
            <p className={`fs-19 mfs-16 mt20 mmt10 ${quickSand.className}`}>Learnsoft IT Training Institute provides industry-relevant courses with expert instructors, offering practical training to help students advance in IT careers</p>
             <div className='mt32 df gap20 fw mmt40'>
             <button className={`explore-btn pdtb10 fs-19 cw mfs-16 popinsfw`}> <Link href={"/course"} className='cw cursor'> Explore Courses </Link> </button>
             <div className='df gap5 fac'>
             <PiPhoneCallBold size={31} color='#FDBB39'/>
             <div className=''>
                <p className={`fs-16 ${quickSand.className}`}>Have any Question?</p>
               <Link href={"tel:+917825888899"}>  <p className={`fs-16 fwb500 ${quickSand.className}`}>+91 7825 88 88 99</p> </Link>
             </div>
             </div>
             </div>
            </div>
            {/* Images */}
            <div className='mpdt20'>
            <Image className='mdn' src={"/home-v2-images/home-vector.webp"} width={600} height={500} alt='Home-Vector' loading='lazy'/>     
            <Image className='dn mdb' layout='responsive' src={"/home-v2-images/home-vector.webp"} width={600} height={500} alt='Home-Vector' loading='lazy'/>     
               {/* <Image className=''  style={{ width: '100%', height: 'auto' }} src={"/home-v2-images/home-vector.webp"} width={600} height={500} alt='Home-Vector' loading='lazy'/>      */}
             </div>
         </div>
       </div>
    </section>
    <style jsx>
        {`
         .home-bg{
            background: linear-gradient(90deg, #fdf4e4 0%, #fce0a1 50%, #fdf4e4 100%);
            width:100%;
            height: auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
           
            background-size: 100% 100%;
        }
            .explore-btn{
            background-color: #FDBB39;
            border-radius: 10px;
            width: 295px;
            border: none;
            }
            @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .home-bg{
                background-image: url('/home-v2-images/home-tab-bg.webp');
            width:100%;
            height: auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
           
            background-size: 100% 100%;
        }
            }
                      @media only screen and (max-width: 1250px){
              .tfdc{
              display: flex;
              flex-direction: column;
              // align-items: center;
              }
        }
        `}
    </style>
    </>
  )
}
