import React from "react";
import { CorporateContactJsonLd, LocalBusinessJsonLd, NextSeo, SocialProfileJsonLd ,LogoJsonLd,WebPageJsonLd, CourseJsonLd, ProductJsonLd} from "next-seo";

export default class NextSeoModule extends React.Component {
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
            <NextSeo
                title={this.props.title}
                description={this.props.description}
                canonical={`https://www.learnsoft.org/${this.props.url}`}
                additionalMetaTags={[
                    {
                      name: "keywords",
                      content: `${this.props.keyword}`,
                    },
                    {
                        name: "revisit-after",
                        content: "3 days",}
                  ]}
                openGraph={{
                    type: 'website',
                    locale:'en_US',
                    url: `https://www.learnsoft.org/${this.props.url}`,
                    title: `${this.props.title}`,
                    description: `${this.props.description}`,
                    images: [
                        {
                            url: `https://www.learnsoft.org/${this.props.img}`,
                            secureUrl:`https://www.learnsoft.org/${this.props.img}`,
                            width: 400,
                            height: 600,
                            type:'image/webp',
                            alt: `${this.props.title} Course Image`,
                        },
                    ],
                    site_name: "learnsoft.org",
                }}
                twitter={{
                  handle: "@learnsoft.org",
                  site: "@Learnsoftorg",
                  cardType: "summary_large_image",
                }}
            />
            <LogoJsonLd
            id="https://www.learnsoft.org/logob.webp"
            name = "Logo - learnsoft.org"
            logo="https://www.learnsoft.org/logob.webp"
            url="https://www.learnsoft.org"
           />
             <WebPageJsonLd
          id="https://www.learnsoft.org"
          url="https://www.learnsoft.org"
          name={`Best software training institue learnsoft.org - ${this.props.breadcrums}`}
          description={this.props.description}
          breadcrumb={{
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name":`${this.props.breadcrums || "All Courses"}`,
                "item": "https://www.learnsoft.org"
              }
            ]
          }}
          potentialAction={{
            "@type": "SearchAction",
            "target": "https://www.learnsoft.org/search?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }}
        />
            <CorporateContactJsonLd
                url="https://www.learnsoft.org"
                logo="https://www.learnsoft.org/logob.webp"
                name = "Contact - learnsoft.org"
                contactPoint={[
                    {
                        telephone: '+917825888899',
                        contactType: 'Enquiry',
                        areaServed: ["IN", "US", "CA"],
                        availableLanguage: ["English", "Tamil"],
                    }
                ]}
            />
            <SocialProfileJsonLd
                type="Organization"
                id="https://www.learnsoft.org"
                name="learnsoft.org"
                url="https://www.learnsoft.org"
                sameAs={[
                    'https://www.facebook.com/Learnsoft.org/',
                    'https://twitter.com/Learnsoftorg',
                    'https://www.linkedin.com',
                    'https://www.instagram.com/learnsoft_org/'
                    
                ]}
            />
            <LocalBusinessJsonLd
                type="LocalBusiness"
                priceRange = "₹"
                id="https://www.learnsoft.org/contact"
                name="learnsoft.org"
                description="learnsoft Provide Training, Internship and Placement"
                url="https://www.learnsoft.org/contact"
                telephone="+917825888899"
                address={{
                    "@type": "PostalAddress",
                    streetAddress: 'No.4/643,ADM Tower Second Floor,VOC street, OMR,Thoraippakkam',
                    addressLocality: 'Chennai',
                    addressRegion: 'Tamil Nadu',
                    postalCode: '600097',
                    addressCountry: 'INDIA',
                }}
                geo={{
                    "@type": "GeoCoordinates",
                    latitude: '12.9322375',
                    longitude: '80.2302378',
                }}
                images={[
                    `https://www.learnsoft.org/${this.props.img}`
                ]}
                openingHoursSpecification ={[
                    {
                      "@type": "OpeningHoursSpecification",
                      "opens": "08:00",
                      "closes": "21:00",
                      "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday"
                      ]
                    }
                  ]}
                  aggregateRating={{
                    "@type": "AggregateRating",
                    ratingValue: "4.9",
                    ratingCount:"12345",
                    reviewCount: "19276",
                    bestRating: "5",
                    worstRating: "1",
                  }}
            />

           
<ProductJsonLd
         type="Product"
         productName="Software Training Courses" // Explicit productName
         name="Best Software Training Institute in Chennai" // Explicit name field
         images={["https://www.learnsoft.org/logo.webp"]}
          description="Join Chennai best software training institute to master Aws, Power Apps, Sailpoint Iam, Aws Data Engineering, and more with expert trainers and placement support."
          brand={{
            "@type": "Organization",
            "name" : "Learnsoftorg - Top Software Training Institute, Chennai",
          }}
          aggregateRating={{
            "@type": "AggregateRating",
            "ratingValue": "4.98",
            "reviewCount": "17292",
            "worstRating":'1',
            "bestRating":"5"

          }}
        />
            </>
        )
    }
}