
import Link from 'next/link'
import { useState } from 'react'
import GetInstantForm from './get-instant-form'
import { Poppins, Quicksand } from 'next/font/google'
const popins = Poppins({weight:"500",subsets:['latin'],})
const quickSand = Quicksand({weight:"600",subsets:['latin'],})

export default function TopHeader() {

  const address =[
    {
      place:"Thoraipakkam",
      mobileNumber :'+917825888899'
    },
    {
      place:"Tirunelveli",
      mobileNumber :'+917825888866'
    },
    {
      place:"Ashok Pillar",
      mobileNumber :'+917825888855'
    },
    {
      place:"keelkattalai",
      mobileNumber :'+917825888844'
    },
  ]

    const [popupForm,setPopupForm] = useState(false);

    const handleFormOpen = ()=>{
        setPopupForm(true)
    }
    const handleFormClose = () => {
        setPopupForm(false);
    };
  return (
    <>
    <section className='container mdn'>
        <div className='df fac fjsb gap11  pdtb12 '>
        <div className='df  gap20 mfdc mgap5'>
       {address.map((items,index)=>{
        return(
       <div className='df' key={index}>
           <p className={`fs-14 mfs-12 ${popins.className}`}> {items.place} : </p>
          <Link className='' href={`tel:${items.mobileNumber}`}>
          <p className={`fs-14 mfs-12 cb  ${quickSand.className}`}> {items.mobileNumber} </p> </Link> 
          </div> 
          )
        })}</div>
        <div className=''>
            <button onClick={handleFormOpen} className={`get-btn fs-19 mfs-12 cursor cw `}>Request a Free Demo</button>
        </div>
        </div>
    </section>
    
    {popupForm && 
    <div className='form-popup'>
    <GetInstantForm formWidth={"620px"} close={handleFormClose} closeIcon={true}/>
    </div>}
    <style jsx>
        {`

        .get-btn{
        background-color: #FFA900;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
        border: none;
        }
         .form-popup {
            position: fixed;
            top: 30%;
            left: 20%;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: fadeIn 0.5s ease-in-out;
            z-index: 200000;
       
          }

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
  @media only screen and (max-width: 550px){
          .get-btn{
        background-color: #FFA900;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
        border: none;
        }
  }
  @media only screen and (max-width: 1023px){
           .form-popup {
            position: fixed;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: fadeIn 0.5s ease-in-out;
            z-index: 200000;
       
          }
  }
        
        `}
    </style>
    </>
  )
}
