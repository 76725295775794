import axios from 'axios';
import { Poppins, Quicksand } from 'next/font/google'
import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const popins = Poppins({weight:"500" , subsets:['latin']})
const quickSand = Quicksand({weight:"500" , subsets:['latin']})


//const API_URL = process.env.NODE_ENV === 'production' ?process.env.NEXT_PUBLIC_API_URL_PROD : process.env.NEXT_PUBLIC_API_URL_DEV;

export default class Form extends Component {
constructor(props){
  super(props);
  this.state = {
    name: '',
    mobileNumber: '',
    email: '',
    classType: '',
    course: '',
    errors: {},
    submissionSuccess: false,
    closeForm:true,
    setloading:false,
    close
  };
}
 
handleInputChange = (field, value) => {
  this.setState({ [field]: value });
};
handleSubmit = async () => {
  const { name, mobileNumber, email, course,classType,CloseFromPopup} = this.state;

  // Validate the form fields
  const errors = {};
  if (!name.trim()) {
    errors.name = 'Name is required';
  }
  if (!mobileNumber.trim()) {
    errors.mobileNumber = 'Mobile Number is required';
  }
  if (!course.trim()) {
    errors.course = 'Please Enter Your Course';
  }

  if (Object.keys(errors).length > 0) {
    this.setState({ errors, submissionSuccess: false });
    return;
  }
  this.setState ({setloading : true})
  try {
     const response = await axios.post(`${process.env.url}/api/sendmail`,{
      name:this.state.name,
      mobileNumber:this.state.mobileNumber,
      email:this.state.email,
      course:this.state.course,
      classType:this.state.classType
     })
     console.log("Form Data",response.data)
    //  console.log("Education Form Has Been Submited Successfully",{name,mobileNumber,email,classType,course})

    //  form submited After Reset Form

    
  this.setState({
    name: '',
    mobileNumber: '',
    email: '',
    classType: '',
    course: '',
    errors: {},
    // submissionSuccess: true,
    setloading: false,
  })
  toast.success('Your form has been submitted successfully!');

  setTimeout(()=>{
    this.setState({    

    // submissionSuccess:false,
    closeForm:false,
  
  })
  },6000)


  } catch (error) {
    console.error('Client-side error:', error); // Log any client-side error
    alert('An error occurred while registering. Please try again.');
     
  }


//   try {
//     const response = await fetch(`${API_URL}/api/sendgrid`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         name: this.state.name,
//         mobileNumber: this.state.mobileNumber,
//         email: this.state.email,
//         course: this.state.course,
//         classType: this.state.classType,
//       }),
//     });

//     if (response.ok) {
//       const data = await response.json();
//       console.log("Form Data", data);
//       this.setState({
//         name: '',
//         mobileNumber: '',
//         email: '',
//         classType: '',
//         course: '',
//         errors: {},
//         submissionSuccess: true,
//       });

//       setTimeout(() => {
//         this.setState({ submissionSuccess: false });
//       }, 5000);

//       // toast.success(`Registration successful for the ${course} course!`);
//     } else {
//       console.error('Server error:', response.statusText);
//       alert('An error occurred while registering. Please try again.');
//     }
//   } catch (error) {
//     console.error('Client-side error:', error);  // Log any client-side error
//     alert('An error occurred while registering. Please try again.');
//   }
// };

}

  render() {
    const { errors,submissionSuccess,closeForm,setloading,close } = this.state;

    if(!closeForm){
      return null;  // Hide the form if formVisible is false
    }
    return (
      <>
      <section className='df fjc fac'>
        <div className='form-center '>
        <div className={`form-box`}>
       
          <div className='form-rad-box'>
            <h6 className={`fs-20 mfs-14 cw ${popins.className}`}>Education for a Brighter Future</h6>
            <h6 className={`fs-20 mfs-14 cw ${popins.className}`}>Register Today!</h6>
            </div>  
            <div className='df fdc fac mt24 mmt0'>
                <input className={`inp-box mt12`} type='text' placeholder='Name' value={this.state.name}
                     onChange={(e) => this.handleInputChange('name', e.target.value)}/>
                     
                     {errors.name && <span className="red-clr fs-12 ">{errors.name}</span>}
 
                <input className={`inp-box mt12`} type='text' placeholder='Course'value={this.state.course}
                      onChange={(e) => this.handleInputChange('course', e.target.value)}/>
 
                       {errors.course && <span className="red-clr fs-12 mfs-10 pdt10 mpdt5">{errors.course}</span>}
             
                <input className={`inp-box mt12`} type='tel' placeholder='Mobile No'value={this.state.mobileNumber}
                       onChange={(e) => this.handleInputChange('mobileNumber', e.target.value)}/>
                 
                        {errors.mobileNumber && <span className="red-clr fs-12 mfs-10 pdt10 mpdt5">{errors.mobileNumber}</span>}
               
                <input className={`inp-box mt12`} type='email' placeholder='Email'value={this.state.email}
                       onChange={(e) => this.handleInputChange('email', e.target.value)}/>    
               
              <select className={`opt-box mt12`} value={this.state.classType}
                onChange={(e) =>
                this.handleInputChange('classType', e.target.value)
                }>
                <option className={`opt-value`} >Class Type</option>
               <option value="online" className={`opt-value`}>Online</option>
               <option value="offline" className={`opt-value`}>Offline</option>
          </select>
         
         <div className={`mt20 df gap20`}>
            <button className={`sub-btn fs-16  ${quickSand.className}`} disabled={setloading} onClick={this.handleSubmit}>
            {setloading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>) : "Submit" }
            </button>
               <button onClick={this.props.close} className={`sub-btn fs-16 cw ${quickSand.className}`}>
               Cancel
               </button>
         </div>
         {submissionSuccess && <div className="success-message fs-19 tac ">Registration successful!</div>}
            </div>
         
        </div>
        </div>
    
        <style jsx>
            {`

  /* Disabled state */
.btn:disabled {
  background-color: #B31942; /* Lighter background color when disabled */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.6; /* Reduce opacity when disabled */
}
/* Spinner container */
.spinner-container {
  display: flex; /* Center spinner */
  align-items: center; /* Vertically center spinner */
  justify-content: center;
}

/* Spinner styles */
.spinner {
  border: 8px solid rgba(0, 0, 0, 0);
  border-left-color: #ffffff; /* Spinner color */
  border-radius: 50%;
  width: 20px; /* Spinner size */
  height: 20px; /* Spinner size */
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

           .success-message{
            color:#FFA900;

           }

      .form-center{          
        width: 500px;
        height: 280px;
       z-index: 1000;
       display:flex;
       flex-direction:column;
       position: fixed; 
       top: 27%;
       left: 50%;
       transform: translate(-50%, -50%);
   
    } 
            
            .form-box{
                width: 400px;
                padding: 0px 0px 20px 0px;
                border-radius: 50px;
                background-color: #FFFFFF;
                box-shadow: 0px 8px 16px 0px #00000040;

            }
            .form-rad-box{
                width: 400px;
                height: 70px;
                border-radius: 50px 50px 0px 0px;
                background-color:#FFA900;
                text-align:center;
                padding-top: 15px;
                
            }
        
            .opt-box{
              width: 313px;
              height: 48px;
              border-radius: 8px;
              border: 1px solid #333333;
              color: #333333;  
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.5px;
              padding: 0px 8px 0px 8px;
            }
            .inp-box{
                width: 295px;
                height: 48px;
                border-radius: 8px;
                border: 1px solid #333333;
                color: #333333;  
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                padding: 0px 8px 0px 8px;
              }
              .sub-btn{
                width:101px;
                height:40px;
                border-radius: 8px;
                background-color: #FFA900;
                border:  none;
                cursor: pointer;
              }
              .opt-value{
                width: 295px;
                height: 96px;
              }
              @media only screen and (max-width: 1023px){
               

                .form-center{          
                   z-index: 1000;
                   display:flex;
                   justify-content:center;
                   align-items:center;
                   flex-direction:column;
                   position: fixed; 
                   top: 50%;
                   left: 50%;
                   transform: translate(-50%, -50%);
                 
                   
                }

                .form-box{
                    width: 290px;
                    height: auto;
                    padding: 0px 0px 40px 0px;
                    border-radius: 25px;
                    gap: 0px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 8px 16px 0px #00000040;
    
                }
                .form-rad-box{
                    width: 290px;
                    height: 50px;
                    border-radius: 25px 25px 0px 0px;
                    background-color:#FFA900;
                    text-align:center;
                    padding-top: 15px;
                    
                }
                .inp-box{
                    width: 170px;
                    height: 28px;
                    border-radius: 4px;
                    border: 1px solid #333333;
                    color: #333333;  
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    padding: 0px 8px 0px 8px;
                  }
                  .opt-box{
                    width: 190px;
                    height: 28px;
                    border-radius: 4px;
                    border: 1px solid #333333;
                    color: #333333;  
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    padding: 0px 8px 0px 8px;
                    
                  }
               
              }
            
            `}
        </style>
      </section>
      <ToastContainer />
    
      </>
      
    )
  }
}
